.haris_films__graduation-photos-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.haris_films__graduation-photos-content h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 50px;
    line-height: 100%;
    text-transform: capitalize;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.haris_films__graduation-photos-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.haris_films__graduation-photos-rates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.haris_films__graduation-photos-rates h2 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 35px;
    line-height: 100%;
    text-transform: capitalize;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: rem;
}

.haris_films__graduation-photos-rates-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.haris_films__graduation-photos-rates h3 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 28px;
    color: #FFFFFF;
    margin-top: 1.5rem;
}

.haris_films__graduation-photos-rates ul {
    list-style-type: disc; /* Change bullet point to circle */
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    margin-top: 0.5rem;
    width: 350px;
  }
  
.haris_films__graduation-photos-rates li {
    line-height: 1.5; /* Add some spacing between items */
}

.haris_films__graduation-photos-rates p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 200;
    font-size: 25px;
    line-height: 28px;
    color: #FFFFFF;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}