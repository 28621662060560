.haris_films__footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5rem;
}

.haris_films__footer-containers {
    position: relative;
    width: 100%;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.haris_films__footer-containers-socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 1.5rem;
}

.haris_films__footer-containers-socials img {
    height: 16px;
    width: 16px;
}

.haris_films__footer-containers-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.haris_films__footer-containers-links p {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;    
    text-transform: capitalize;

    margin: 1.5rem;
}

.haris_films__footer-containers-copyright {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.haris_films__footer-containers-copyright p {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 28px;
    color: #8f8f8f;    
}