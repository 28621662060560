.haris_films__about {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.haris_films__about-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 22rem; 

    height: 416px;
    width: 1500px;
    z-index: 3;
    background: linear-gradient(240.84deg, #312a62 -5.33%, rgba(29, 85, 111, 0) 96.31%);
    border-radius: 30px;
}

.haris_films__about-content-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 3rem;
}

.haris_films__about-profile-content-img-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.haris_films__about-profile-content-img {
    position: relative;
    height: 350px;
    width: 350px; 
    overflow: hidden; 
    border-radius: 50%;
}

.haris_films__about-profile-content-img img {
    display: inline;
    width: auto; 
    height: 100%; 
    margin-left: -48%;
    margin-top: 0%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
}

.haris_films__about-profile-content-container {
    width: 50%;
}

.haris_films__about-profile-content-h1 {
    font-family: 'Outfit';
    font-size: 50px;
    text-transform: capitalize;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.haris_films__about-profile-content-p1 {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 28px;
    margin-top: 1.5rem;
    color: #FFFFFF;    

    width: 600px;
}

.haris_films__about-profile-content-p2 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin-top: 1.5rem;
    color: #FFFFFF;    
}

.haris_films__about-profile-content-container button {
    background: #8E8EDB;
    width: 255px;
    height: 58px;
    border: 1px solid #000000;
    border-radius: 117px;
    margin-top: 2.3rem;
    margin-bottom: 0.5rem;

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
}


