.haris_films__services-service {
    position: relative;
    width: 450px;
    height: 450px;
    overflow: hidden; 
    border-radius: 10%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.haris_films__services-title {
    position: absolute; /* Add absolute positioning to the text/button element */
    top: 50%; /* Position the text/button element at the vertical center of the parent container */
    left: 50%; /* Position the text/button element at the horizontal center of the parent container */
    transform: translate(-50%, -50%); /* Center the text/button element precisely */
    text-align: center;
}

.haris_films__services-title button {
    background: none;
    border: none;
    width: 100%;
    height: 100%;
    white-space: nowrap;

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 
    3px 3px 0px #000000,
    3px 2.7px 2.5px #000000,
    2px 3px 2.5px #4b13a6;
    cursor: pointer;
}


.haris_films__services-service img {
    display: block;
    width: auto; 
    height: 100%; 
    margin-left: -25%;
    margin-top: 0%;
    opacity: .9;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
