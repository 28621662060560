.haris_films__header {
    display: flex;
    width: 100%;

}

.haris_films__header-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%; 
}

.haris_films__header-content-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    z-index: 1;
    margin-top: 14.5rem; 
    min-width: 0;

}

.haris_films__header-content-container h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 80px;
    text-indent: -3.9px;
    line-height: 100%;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-shadow:
    -1px -1px 0 #000,
     0   -1px 0 #000,
     1px -1px 0 #000,
     1px  0   0 #000,
     1px  1px 0 #000,
     0    1px 0 #000,
    -1px  1px 0 #000,
    -1px  0   0 #000;
    
    text-transform: capitalize;
    color: #FFFFFF;
}

.haris_films__header-content-container p {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    margin-top: 1rem;
}

.haris_films__header-content-btns {
    display: flex;
}

.haris_films__header-content-btns button {
    font-family: var(--font-family);
    /*revisit font weight*/
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    width: 255px;
    height: 58px;
    box-sizing: border-box;
    position: relative;
    margin-top: 3rem;


    color: #FFFFFF;
    background: #8E8EDB;
    border: 1px solid #000000;
    border-radius: 20px;
}

.haris_films__header-content_video video {
    position: absolute;
    object-fit: cover;
    margin: 0 -6rem;
    width: 100vw;
    height: 700px;
    top: 0;
    bottom:0;
    right:0;
    left: 0;
    z-index: 0;
    overflow: hidden;

}

/* @media screen and (max-width: 4000px) {
    .haris_films__header-content-container {
        margin-top:16.5rem;
    }
}

@media screen and (max-width: 1050px) {
    .haris_films__header-content-container {
        margin-top:12.5rem;
    }
    .haris_films__header-content h1 {
        text-indent: -1px;

        font-size: 60px;
    }

    .haris_films__header-content-container p {
        font-size: 16px;
        margin-top: 0.5rem;
    }

    .haris_films__header-content-btns button {
        margin-top:1.5rem;
        font-size: 10px;
        width: 140px;
        height:32px;
    }
} */

/* @media screen and (max-width: 800px) {
    .haris_films__header-content-container {
        margin-top:6.5rem;
    }
    .haris_films__header-content h1 {
        text-indent: -1px;

        font-size: 60px;
    }

    .haris_films__header-content-container p {
        font-size: 16px;
        margin-top: 0.5rem;
    }

    .haris_films__header-content-btns button {
        margin-top:1.5rem;
        font-size: 10px;
        width: 140px;
        height:32px;
    }
} */

/* @media screen and (max-width: 600px) {
    .haris_films__header-content-container {
        margin-top:5.5rem;
    }
    .haris_films__header-content h1 {
        text-indent: -1px;

        font-size: 30px;
    }

    .haris_films__header-content-container p {
        font-size: 11px;
        margin-top: 0.5rem;
    }

    .haris_films__header-content-btns button {
        margin-top:1.5rem;
        font-size: 10px;
        width: 140px;
        height:32px;
    }
}

@media screen and (max-width: 500px) {
    .haris_films__header-content-container {
        margin-top:3rem;
    }
    .haris_films__header-content h1 {
        text-indent: 0px;
        font-size: 30px;
    }

    .haris_films__header-content-container p {
        font-size: 11px;
        margin-top: .5rem;
    }

    .haris_films__header-content-btns button {
        margin-top:1.5rem;
        font-size: 10px;
        width: 140px;
        height:32px;
    }
} */